<template>
  <div>
    <el-card>
      <!-- 第一行，添加按钮 -->
      <el-row>
        <!-- 添加按钮 -->
        <el-col :span="5">
          <el-button
            type="primary"
            @click="showAddActiveDialog"
          >+ 新增活动</el-button>
        </el-col>
      </el-row>
      <!-- 第二行，筛选 -->
      <div class="secondrow">
        <!-- 根据状态查询 -->
        <div class="input">
          活动类型：
          <el-select
            v-model="queryInfo.activityType"
            placeholder="请选择活动状态"
            @change="searchActive"
            class="select1"
          >
            <el-option
              v-for="item in taskActivity"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          活动状态：
          <el-select
            v-model="queryInfo.status"
            clearable
            @clear="getActiveList"
            placeholder="请选择活动状态"
            @change="searchActive"
            class="select1"
          >
            <el-option
              v-for="item in statusList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 内容区域 -->
      <el-table
        :data="activeList"
        stripe
        style="width: 100%"
        border
      >
        <!-- 第一列id -->
        <el-table-column
          label="id"
          width="100"
          aligin="center"
          prop="id"
        >
        </el-table-column>
        <!-- 名称 -->
        <el-table-column
          label="活动名称"
          aligin="center"
          prop="activityName"
        >
        </el-table-column>
        <!-- 活动时间 -->
        <el-table-column
          label="活动时间"
          width="300"
          aligin="center"
        >
          <template slot-scope="scope">
            <div>
              {{ scope.row.activityStart }} - {{ scope.row.activityEnd }}
            </div>
          </template>
        </el-table-column>
        <!-- 状态 -->
        <el-table-column
          label="状态"
          aligin="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.status === 0">
              上架
            </div>
            <div v-else>下架</div>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column
          label="操作"
          width="300px"
          aligin="center"
        >
          <!-- 发布按钮 -->
          <template slot-scope="scope">
            <el-link
              style="margin-left: 20px;"
              :underline="false"
              type="primary"
              v-if="scope.row.activityType === 8"
              @click="register(scope.row.id)"
            >登记记录</el-link>
            <el-link
              style="margin-left: 20px;"
              :underline="false"
              type="success"
              @click="issue(scope.row)"
              v-if="scope.row.status === 1"
            >上架活动</el-link>
            <el-link
              style="margin-left: 20px;"
              :underline="false"
              type="warning"
              @click="issue(scope.row)"
              v-if="scope.row.status === 0"
            >下架活动</el-link>

            <el-link
              style="margin-left: 20px;"
              :underline="false"
              type="primary"
              @click="showEditActiveDialog(scope.row)"
            >修改</el-link>
            <!-- 删除按钮 -->
            <el-link
              style="margin-left: 20px;"
              :underline="false"
              type="danger"
              @click="removeActiveById(scope.row.id)"
            >删除</el-link>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加对话框 -->
    <el-dialog
      @close="clearAddInfo"
      :title="title"
      :visible.sync="addActiveDialogVisible"
      width="700px"
    >
      <el-radio-group
        v-model="agriculturalActivityForm.activityType"
        style="display:flex;"
        :disabled='disableOne'
      >
        <el-radio
          :label="activityLabel1"
          border
        >线下活动</el-radio>
        <el-radio
          :label="activityLabel2"
          border
        >发放优惠券</el-radio>
      </el-radio-group>
      <el-form
        ref="addActiveFormRef"
        :rules="addActiveRules"
        :model="agriculturalActivityForm"
        label-width="100px"
        v-if="this.agriculturalActivityForm.activityType === 8"
      >
        <el-form-item label="活动名称">
          <el-input
            v-model="slectActiveName.offlineActiveName"
            placeholder="请输入活动名称"
            class="select"
            clearable=""
          >
          </el-input>
        </el-form-item>
        <el-form-item label="活动时间">
          <el-date-picker
            v-model="slectActiveTime.offlineActiveTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="changeGrantTime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="针对人群"
          prop="memberGradeId"
        >
          <el-select
            v-model="agriculturalActivityForm.memberGradeId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in forPeopleOpt"
              :key="item.id"
              :label="item.gradeName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item
          label="活动说明"
          prop="activityDescription"
        >
          <el-input
            v-model="agriculturalActivityForm.activityDescription"
            type="textarea"
            clearable=""
          >
          </el-input>
        </el-form-item> -->
      </el-form>
      <el-form
        v-else
        ref="addActiveFormRef"
        :rules="addActiveRules"
        :model="agriculturalActivityForm"
        label-width="100px"
      >
        <el-form-item label="活动名称">
          <el-input
            v-model="slectActiveName.couponActiveName"
            placeholder="请输入活动名称"
            class="select"
            clearable=""
          >
          </el-input>
        </el-form-item>
        <el-form-item label="活动时间">
          <el-date-picker
            v-model="slectActiveTime.couponActiveTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="changeGrantTime"
          >
          </el-date-picker>
        </el-form-item>
        <div class="addbtn">
          <el-button @click="addUser">增加优惠券</el-button>
        </div>
        <!-- 动态生成 -->
        <div class="moreRules">
          <div
            class="moreRulesIn"
            v-for="(item, index) in agriculturalActivityForm.activityCouponList"
            :key="item.key"
          >
            <div class="add-input">
              <el-form-item label="优惠券">
                <el-select
                  v-model="item.couponId"
                  placeholder="请选择优惠券"
                >
                  <el-option
                    v-for="item2 in couponList"
                    :key="item2.id"
                    :label="item2.couponName"
                    :value="item2.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="活动方式"
                :prop="'activityCouponList.' + index + '.event'"
                :rules="[{ required: true, message: '请选择活动方式' }]"
              >
                <el-select
                  v-model="item.event"
                  placeholder="请选择活动方式"
                >
                  <el-option
                    v-for="(item2, index2) in event"
                    :key="index2"
                    :label="item2.label"
                    :value="item2.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="add-input">
              <el-form-item
                label="优惠券数量"
                :prop="'activityCouponList.' + index + '.number'"
                :rules="[{ required: true, message: '请优惠券数量' }]"
              >
                <el-input-number
                  v-model="item.number"
                  placeholder="请输入优惠券数量"
                ></el-input-number>
              </el-form-item>
              <el-form-item
                label="最多可得优惠券数量"
                :prop="'activityCouponList.' + index + '.maxNumber'"
                :rules="[{ required: true, message: '请输入最大优惠券数量' }]"
              >
                <el-input-number
                  v-model="item.maxNumber"
                  placeholder="请输入最大优惠券数量"
                ></el-input-number>
              </el-form-item>
            </div>
            <div class="add-input">
              <el-button
                plain
                @click="deleteRules(item, index)"
              >删除</el-button>
            </div>
          </div>
        </div>
        <!-- 引用结束 -->
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="addActiveDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      disableOne: false,
      activityLabel1: 8,
      activityLabel2: 0,
      // 请求入参
      queryInfo: {
        // activityName: '', // 活动名称
        limit: 15,
        pageNo: 1,
        status: '',
        activityType: 0
        // marketId: ''
      },
      total: 0,
      // 请求券入参
      queryInfo2: {
        couponName: undefined, // 券名称
        startTime: undefined,
        endTime: undefined,
        limit: 200,
        pageNo: 1,
        status: 0,
        marketId: ''
      },
      // 活动列表
      activeList: [],
      // 券列表
      couponList: [],
      // 状态
      statusList: [
        {
          id: 0,
          name: '上架'
        },
        {
          id: 1,
          name: '下架'
        }
      ],
      // 新增对话框
      addActiveDialogVisible: false,
      // 新增和修改公用一个对话框
      title: '',
      submitType: '',
      // 修改的id
      id: '',
      // 新增入参
      // 活动时间
      slectActiveTime: {
        offlineActiveTime: [], // 线下活动的activeTime-左边
        couponActiveTime: []// 右边
      },
      slectActiveName: {
        offlineActiveName: '', // 左边
        couponActiveName: '' // 右边
      },
      agriculturalActivityForm: {
        activityEnd: '', // 提交的值
        activityStart: '', // 提交的值
        status: undefined,
        activityType: 8, // 活动类型
        memberGradeId: '', // 针对人群 左边
        // activityDescription: '', // 描述 左边
        activityCouponList: [ // 右边
          {
            couponId: '', // id
            event: '', // 方式
            number: '', // 数量
            maxNumber: '' // 最多
          }
        ]
      },
      // 规则
      addActiveRules: {
        activityName: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ]
      },
      // 活动方式
      event: [
        {
          label: '首充',
          value: 'first_charge'
        },
        {
          label: '注册',
          value: 'register'
        },
        {
          label: '下单',
          value: 'order'
        }
      ],
      radioSelect: '0',
      // 针对人群列表
      forPeopleOpt: [],
      taskActivity: [
        {
          id: 0,
          name: '优惠券'
        },
        {
          id: 8,
          name: '线下活动'
        }
      ]
    }
  },
  created () {
    // 获取活动列表
    this.getActiveList()
    // 获取优惠券列表
    this.getCouponList()
    this.getMemberLevel()
  },
  methods: {
    searchActive () {
      this.queryInfo.pageNo = 1
      this.getActiveList()
    },
    // 定义方法
    async getActiveList () {
      const { data: res } = await this.$http.get('/agriculturalActivity/list', { params: this.queryInfo })
      if (res.code !== 0) {
        this.$message.error(res.message)
      }
      //  成功后赋值
      this.activeList = res.data.data
      this.total = res.data.total
    },
    // 会员等级
    async getMemberLevel () {
      const { data: res } = await this.$http.get('/memberGrade/list')
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.forPeopleOpt = res.data.data
    },
    // 获取优惠券
    async getCouponList () {
      this.queryInfo2.marketId = window.sessionStorage.getItem(
        'currentMarketId'
      )
      const { data: res } = await this.$http.get(
        'agriculturalCoupon/agriculturalCouponList',
        {
          params: this.queryInfo2
        }
      )
      if (res.code !== 0) {
        this.$message.error(res.message)
      }
      //  成功后赋值
      this.couponList = res.data.data
    },
    // 打开新增对话框
    showAddActiveDialog () {
      this.disableOne = false
      this.title = '新增活动'
      this.submitType = 'add'
      this.addActiveDialogVisible = true
    },
    // 修改
    async showEditActiveDialog (row) {
      this.title = '修改活动'
      this.submitType = 'edit'
      this.id = row.id
      const { data: res } = await this.$http.get(`/agriculturalActivity/${this.id}`)
      console.log(res)
      this.agriculturalActivityForm = res.data
      // this.agriculturalActivityForm = row
      this.agriculturalActivityForm.activityCouponList = res.data.activityCouponList
      this.agriculturalActivityForm.activityType === 8 ? this.slectActiveTime.offlineActiveTime = [res.data.activityStart, res.data.activityEnd] : this.slectActiveTime.couponActiveTime = [res.data.activityStart, res.data.activityEnd]
      this.agriculturalActivityForm.activityType === 8 ? this.slectActiveName.offlineActiveName = res.data.activityName : this.slectActiveName.couponActiveName = res.data.activityName
      if (this.agriculturalActivityForm.activityType === 8) {
        this.slectActiveTime.couponActiveTime = []
        this.slectActiveName.couponActiveName = ''
        const init = [ // 右边
          {
            couponId: '', // id
            event: '', // 方式
            number: '', // 数量
            maxNumber: '' // 最多
          }
        ]
        this.activityCouponList = init
      } else {
        this.slectActiveTime.offlineActiveTime = []
        this.slectActiveName.offlineActiveName = ''
        this.agriculturalActivityForm.memberGradeId = ''
        this.agriculturalActivityForm.activityDescription = ''
      }
      this.disableOne = true
      console.log(res.data)
      this.addActiveDialogVisible = true
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getActiveList()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getActiveList()
    },
    // 删除
    async removeActiveById (id) {
      // 弹框提醒
      const confirmResult = await this.$confirm(
        '此操作将永久删除该活动，是否继续？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除')
      }
      const { data: res } = await this.$http.delete(
        `/agriculturalActivity/${id}`
      )
      if (res.code !== 0) {
        return this.$message.error('删除活动失败')
      }
      this.$message.success('删除活动成功')
      this.getActiveList()
    },
    // 领取时间
    changeGrantTime () {
      this.agriculturalActivityForm.activityStart = this.agriculturalActivityForm.activityType === 8 ? this.slectActiveTime.offlineActiveTime[0] : this.slectActiveTime.couponActiveTime[0]
      this.agriculturalActivityForm.activityEnd = this.agriculturalActivityForm.activityType === 8 ? this.slectActiveTime.offlineActiveTime[1] : this.slectActiveTime.couponActiveTime[1]
    },
    // 关闭对话框
    clearAddInfo () {
      this.slectActiveName.offlineActiveName = ''
      this.slectActiveName.couponActiveName = ''
      this.agriculturalActivityForm.memberGradeId = ''
      // this.agriculturalActivityForm.activityDescription = ''
      this.slectActiveTime.offlineActiveTime = []
      this.slectActiveTime.couponActiveTime = []
      this.agriculturalActivityForm.activityCouponList = []
      this.getActiveList()
    },
    // 提交请求
    submit () {
      if (this.agriculturalActivityForm.activityType === 0) {
        const coupon = {
          activityStart: this.agriculturalActivityForm.activityStart,
          activityEnd: this.agriculturalActivityForm.activityEnd,
          activityName: this.agriculturalActivityForm.activityType === 8 ? this.slectActiveName.offlineActiveName : this.slectActiveName.couponActiveName,
          activityType: 0,
          activityCouponList: this.agriculturalActivityForm.activityCouponList
        }
        this.$refs.addActiveFormRef.validate(async valid => {
          if (!valid) return
          // 可以发起网络请求
          if (this.submitType === 'add') {
            const { data: res } = await this.$http.post(
              '/agriculturalActivity',
              coupon
            )
            if (res.code !== 0) {
              return this.$message.error(res.message)
            }
            this.$message.success('添加活动成功')
            this.getActiveList()
            this.addActiveDialogVisible = false
          } else {
            const { data: res } = await this.$http.put(
              `/agriculturalActivity/${this.id}`,
              coupon
            )
            if (res.code !== 0) {
              return this.$message.error(res.message)
            }
            this.$message.success('修改活动成功')
            this.getActiveList()
            this.addActiveDialogVisible = false
          }
        })
      } else if (this.agriculturalActivityForm.activityType === 8) {
        const params = {
          activityStart: this.agriculturalActivityForm.activityStart,
          activityEnd: this.agriculturalActivityForm.activityEnd,
          activityName: this.agriculturalActivityForm.activityType === 8 ? this.slectActiveName.offlineActiveName : this.slectActiveName.couponActiveName,
          memberGradeId: this.agriculturalActivityForm.memberGradeId,
          // activityDescription: this.agriculturalActivityForm.activityDescription,
          activityType: 8
        }
        this.$refs.addActiveFormRef.validate(async valid => {
          if (!valid) return
          // 可以发起网络请求
          if (this.submitType === 'add') {
            const { data: res } = await this.$http.post(
              '/agriculturalActivity',
              params
            )
            if (res.code !== 0) {
              return this.$message.error(res.message)
            }
            this.$message.success('添加活动成功')
            this.getActiveList()
            this.addActiveDialogVisible = false
          } else {
            const { data: res } = await this.$http.put(
              `/agriculturalActivity/${this.id}`,
              params
            )
            if (res.code !== 0) {
              return this.$message.error(res.message)
            }
            this.$message.success('修改活动成功')
            this.getActiveList()
            this.addActiveDialogVisible = false
          }
        })
      }
    },
    // 发布
    async issue (row) {
      if (row.status === 0) {
        row.status = 1
      } else {
        row.status = 0
      }
      const { data: res } = await this.$http.put(
        `/agriculturalActivity/${row.id}`,
        row
      )
      if (res.code !== 0) {
        this.$message.error('修改活动状态失败')
      }
      this.$message.success('修改活动状态成功')
      this.getActiveList()
    },
    addUser () {
      if (this.agriculturalActivityForm.activityCouponList) {
        this.agriculturalActivityForm.activityCouponList.push({
          couponId: '',
          event: '',
          number: '', // 数量
          maxNumber: '' // 最多
        })
      } else {
        this.agriculturalActivityForm.activityCouponList = [
          {
            couponId: '',
            event: '',
            number: '', // 数量
            maxNumber: '' // 最多
          }
        ]
      }
    },
    deleteRules (item, index) {
      this.index = this.agriculturalActivityForm.activityCouponList.indexOf(
        item
      )
      if (index !== -1) {
        this.agriculturalActivityForm.activityCouponList.splice(index, 1)
      }
    },
    register (id) {
      // console.log(id, '111')
      this.$router.push({ path: '/register', query: { id: id } })
    }
  }
}
</script>

<style lang="less" scoped>
.addbtn {
  margin-bottom: 20px;
}
.el-row {
  margin-bottom: 20px;
}
.el-input {
  width: 240px;
}
.input {
  display: flex;
  width: 340px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.input:nth-child(1) {
  margin-left: 0px;
}
.el-select {
  width: 180px;
}
.secondrow {
  display: flex;
  margin-left: -20px;
}
.el-table {
  width: 1231px !important;
}
.el-radio {
  display: block;
  margin-bottom: 20px;
}
span {
  margin: 0 10px;
}
.input3 {
  width: 170px;
}
.input4 {
  width: 600px;
}
.add-input {
  display: flex;
  justify-content: space-between;
}
.moreRulesIn {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}
</style>
